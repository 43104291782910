// Flexbox
.flex {
    display: flex;
}

.in-line-content {
    flex-direction: row;
}

.column-content {
    flex-direction: column;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.content-align-center {
    align-items: center;
}

.content-align-top {
    align-items: flex-start;
}

.justify-to-right {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.flex-warp {
    flex-wrap: wrap;
}