.footer-width {
    width: 100vw;
    max-width:100% /* Solve horizontal overflow*/
}

.position-absolute-left {
    position: absolute;
    left: 0;
}

.social-logo {
    width: 1.5rem;
    height: 1.5rem;
}