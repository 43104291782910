body,
html,
#root {
    height: 100%;
    width: 100%;
    font-family: $font-default;
}

* {
    box-sizing: border-box;
}

.border-box {
    box-sizing: border-box
}

////////////////////////////////////////////////////////////////////////////////

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: 50%;
}

.percent-25-width {
    width: 25%;
}

.percent-45-width {
    width: 45%;
}

.percent-55-width {
    width: 55%;
}

.content-max-width {
    max-width: 1440px;
}

.width-fit-content {
    width: fit-content;
}

.main-logo {
    height: 2.06rem;
    pointer-events: none;
}

.company-logo {
    height: 4rem;
    pointer-events: none;
}

.cropped-image {
    width: 100%; /* width of container */
    height: 18.75rem; /* height of container */
    object-fit: none;
    object-position: 50% 0%; /* try 20px 10px */ 
}

// Margins and paddings
.margin-0 {
    margin: 0;
}

.margin-top-8 {
    margin-top: 0.5rem;
}

.margin-top-16 {
    margin-top: 1rem;
}

.margin-top-32 {
    margin-top: 2rem;
}

.margin-top-64 {
    margin-top: 4rem;
}

.margin-bottom-16 {
    margin-bottom: 1rem;
}

.margin-bottom-32 {
    margin-bottom: 2rem;
}

.margin-bottom-80 {
    margin-bottom: 5rem;
}

.margin-left-16 {
    margin-left: 1rem;
}

.margin-left-right-16 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.margin-right-8 {
    margin-right: 0.5rem;
}

.margin-right-16 {
    margin-right: 1rem;
}

.padding-8 {
    padding: 0.5rem;
}

.padding-16 {
    padding: 1rem;
}

.padding-32 {
    padding: 2rem;
}

.padding-48 {
    padding: 3rem;
}

.padding-64 {
    padding: 4rem;
}

.padding-left-16 {
    padding-left: 1rem;
}

.padding-rigth-16 {
    padding-right: 1rem;
}

.padding-top-32 {
    padding-top: 2rem;
}

.padding-bottom-32 {
    padding-bottom: 2rem;
}

.border-radius-8 {
    border-radius: 0.5rem;
}

.border-radius-16 {
    border-radius: 1rem;
}

// Backgrounds
.highligth-background-color {
    background-color: $highlight-color;
}

.standard-background-color {
    background-color: $terciary-color;
}

.dark-background-color {
    background-color: $primary-color;
}