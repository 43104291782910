a {
    color: $primary-color;
    text-decoration: none;
}

h1 {
    font-size: $h1-text-size;
    font-weight: $h1-text-weight;
    line-height: $h1-text-line-height;
    color: $primary-color;
}

h2 {
    font-size: $h2-text-size;
    font-weight: $h2-text-weight;
    line-height: $h2-text-line-height;
    color: $primary-color;
}

h3 {
    font-size: $h3-text-size;
    font-weight: $h3-text-weight;
    line-height: $h3-text-line-height;
    color: $primary-color;
}

h4 {
    color: $primary-color;
}

h5 {
    font-size: $h5-text-size;
    font-weight: $h5-text-weight;
    line-height: $h5-text-line-height;
    color: $primary-color;
}

p,
span {
    font-size: $parragraph-text-size;
    font-weight: $parragraph-text-weight;
    line-height: $parragraph-text-line-height;
    color: $secundary-color;
}

.text-aligment-center {
    text-align: center;
}

.text-highlight-color {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: $highlight-color;
}

.text-light-color {
    color: $light-color;
}

.text-dark-color {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: $primary-color;
}

.line-height-0 {
    line-height: 0;
}

@media (max-width: 1020px) {
    h1 {
        font-size: 2.7rem;
        line-height: 4rem;
    }

    h2 {
        font-size: 2rem;
        line-height: 3rem;
    }

    p,
    span {
        font-size: 0.9rem;
    }

    .padding-32 {
        padding: 2rem 0.5rem;
    }
}
