.card-max-width {
    max-width: 100%;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
}

.image-container {
    background-color: $terciary-color;
    padding: 1rem;
    border-radius: 0.55rem;
}

.image-size {
    height: 15rem;
    border-radius: 0.55rem;
}

.text-width {
    max-width: 22rem;
}

@media only screen and (min-width: 768px) {
    .card-max-width {
        max-width: 23.75rem;
    }
}