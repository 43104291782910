.button-style {
    padding: 0.875rem 1.5rem 0.875rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.button-style.dark {
    background-color: $primary-color;
    color: $light-color;
}

.button-style.standard {
    background-color: $terciary-color;
    color: $primary-color;
}

.button-style.highlight {
    background-color: $highlight-color;
    color: $light-color;
}

.button-style.light {
    background-color: $light-color;
    color: $primary-color;
}

.button-style.transparent {
    color: $secundary-color;
    padding: 0.875rem
}

.button-style.transparent.selected {
    color: $primary-color;
}

.button-style.dark:active {
    background-color: $primary-color-alt;
    color: $light-color;
}

.button-style.standard:active {
    background-color: $terciary-color-alt;
    color: $primary-color;
}

.button-style.highlight:active {
    background-color: $highlight-color-alt;
    color: $light-color;
}

.button-style.transparent:hover {
    color: $primary-color;
}
