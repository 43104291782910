#large-menu {
    display: none;
}

#small-menu {
    display: flex;
}

.halfWidthMax{
    width: 100%;
}

.percent-45-width-max{}

#learn-more-seccion{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
}

#learn-more-seccion.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#learn-more-seccion .image-container {
    background-color: $terciary-color;
    padding: 1rem;
    border-radius: 0.55rem;
}

#learn-more-seccion .image-size {
    height: 18rem;
    border-radius: 1rem;
}

#learn-more-seccion .green-image-size {
    height: 18rem;
    border-radius: 1rem;
}

#footer{
    display: flex;
    flex-direction: column;
    text-align: center;
}

#footer-item{
    width: 100%;
}


/* Medium devices (landscape tablets, 1020px and up) */
@media only screen and (min-width: 1020px) {
    #large-menu {
        display: flex;
    }

    #small-menu {
        display: none;
    }

    .halfWidthMax {
        max-width: 50%;
    }

    .percent-45-width-max{
        width: 45%;
    }

    #learn-more-seccion {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }

    #learn-more-seccion.about{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }
    
    #learn-more-seccion .image-size {
        height: 100%;
        width: 100%;
    }

    #learn-more-seccion .green-image-size {
        width: 100%;
        object-fit: cover;
        height: 25vw;
        max-height: 22rem;
    }

    #footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }

    #footer-item{
        width: 25%;
    }

    #footer-item:not(:first-child){
        display: flex;
        justify-content: center;
        width: 25%;
    }
}