@import url(https://fonts.googleapis.com/css?family=Space+Grotesk);

// Colors
$primary-color: #1D1D1B;
$primary-color-alt: #373734;
$secundary-color: #898989;
$terciary-color:#F5F6F8;
$terciary-color-alt:#D7DBE3;
$highlight-color: #00997C;
$highlight-color-alt: #00CCA5;
$light-color: #FFFFFF;

// Text size
$h1-text-size: 4rem;
$h2-text-size: 2.6rem;
$h3-text-size: 1.31rem;
$h5-text-size: 1rem;
$parragraph-text-size: 1rem;

// Text weight
$h1-text-weight: 700;
$h2-text-weight: 700;
$h3-text-weight: 700;
$h5-text-weight: 500;
$parragraph-text-weight: 400;

// Text line height
$h1-text-line-height: 5.10rem;
$h2-text-line-height: 4.2rem;
$h3-text-line-height: 2.06rem;
$h5-text-line-height: 1.6rem;
$parragraph-text-line-height: 1.6rem;

// Fonts
$font-default: 'Space Grotesk';
