/* ......../ media query /.......... */

@media (max-width: 1020px) {
    .menu-container .hamburger-lines {
        display: block;
        height: 23px;
        width: 35px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .menu-container .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
    }

    .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }

    .menu-toggle.open .hamburger-lines .line1 {
        transform: rotate(35deg);
    }

    .menu-toggle.open .hamburger-lines .line2 {
        transform: scaleY(0);
    }

    .menu-toggle.open .hamburger-lines .line3 {
        transform: rotate(-35deg);
    }

    .menu-toggle.open .menu-container {
        display: block;
        position: fixed;
        top: 37px;
        left: 8px;
        z-index: 5;
    }

    .emptydiv {
        display: none;
    }

    .menu-toggle.open .emptydiv {
        display: block;
        position: relative;
        width: 35px;
        z-index: 0;
    }

    .menu {
        display: flex;
        flex-direction: column;
        position: fixed;
        padding-top: 90px;
        padding-left: 30px;
        top: 0;
        left: -90%;
        background-color: $light-color;
        opacity: 0.97;
        width: 90%;
        height: 100vh;
        transition: left 0.4s ease;
    }

    .menu.open {
        display: flex;
        position: fixed;
        left: 0;
    }

}
